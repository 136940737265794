@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


:root{
  --primary: #C73F24;
  --secondary: #141414;
  --third: #FEF6F7;
  --last: #FFFDF7;
  /* --primary: #00CF5D; */
}


*,::after,::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  @apply bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-white
}
.nav-item{
  @apply hover:bg-primary py-2 px-3 h-10 rounded-lg hover:text-gray-200 cursor-pointer hover:border-none transition-colors delay-200
}

p{
  @apply my-2
}

.hero-section {
  background: linear-gradient(135deg, #FF6B6B, #6B47FF);
  color: white; /* Set the text color to contrast with the background */
  padding: 100px; /* Adjust padding as needed */
  text-align: center;
}









code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.double{
  /* margin-bottom: 20px; */
  margin-top: 8px;
  padding-bottom: 5px;
  position: relative;
}

.double:after {
  border-radius: 8px;
  bottom: 1px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 64px;
}


.second-image {
  position: relative; /* Add this line */
}

.second-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 0%, rgba(242, 226, 226, 0.356) 100%);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.second-image:hover::before {
  opacity: 1;
}


